
import { createRouter, createWebHistory } from "vue-router";


// import Homepage from '@/pages/homepage/HomePage.vue'


// import AboutUs from "@/pages/company/AboutusPage.vue"
// import ContactUs from "@/pages/company/ContactusPage.vue"
// import Career from "@/pages/company/CareerPage.vue"
// import Team from "@/pages/company/TeamPage.vue"

// import Product from "@/pages/product/ProductPage.vue"
// import Company from "@/pages/company/CompanyPage.vue"
// import Solution from "@/pages/product/SolutionPage.vue"
// import Api from "@/pages/product/ApiPage.vue"
// import Self from "@/pages/product/SelfPage.vue"
// import Refill from "@/pages/product/RefillPage.vue"
// import Staking from "@/pages/product/StakingPage.vue"
// import Vault from "@/pages/product/VaultPage.vue"

// import Legal from "@/pages/legal/LegalPage.vue"
// import Term from "@/pages/legal/TermPage.vue"
// import Privacy from "@/pages/legal/PrivacyPage.vue"


// import Developer from "@/pages/developer/DeveloperPage.vue"
// import Status from "@/pages/developer/StatusPage.vue"
// import Supported from "@/pages/developer/SupportedPage.vue"

// import StaticPost from '@/pages/static-post/StaticPost.vue'
// import NotFound from '@/pages/NotFound.vue'
// import Register from "@/pages/RegisterPage.vue"

// import Resource from "@/pages/reosurce/ResourcePage.vue"
// import Education from "@/pages/reosurce/EducationPage.vue"
// import FAQ from "@/pages/reosurce/FaqPage.vue"


// import Bounty from "@/pages/developer/BountyPage.vue"
// import Form from "@/pages/developer/FormPage.vue"

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(){
    return { top: 0 }
  },
  
  routes: [
    {
      path: "/",
      component: () => import('@/pages/homepage/HomePage.vue'),
      name:"home",

    },
    {
      path: "/register",
      component: () => import('@/pages/RegisterPage.vue'),
      name:"register"
    },
    {
      path: "/product",
      component: () => import('@/pages/product/ProductPage.vue'),
      name:"product",
      children:[
        {
          path: "business-solution",
          component: () => import('@/pages/product/SolutionPage.vue'),
          name:"solution",
          meta: {title:'Business Solution'}
        },
        // {
        //   path: "self-custody",
        //   component: () => import('@/pages/product/SelfPage.vue'),
        //   name:"self",
        //   meta: {title: 'Self Custody'}
        // },
        // {
        //   path: "wallet-api-sdk",
        //   component: () => import('@/pages/product/ApiPage.vue'),
        //   name:"api",
        //   meta: {title: 'Wallet API & SDK'}
        // },
        // {
        //   path: "smart-refill-wallet",
        //   component: () => import('@/pages/product/RefillPage.vue'),
        //   name:"refill",
        //   meta: {title: 'Smart Refill Wallet'}
        // },
        // {
        //   path: "staking",
        //   component: () => import('@/pages/product/StakingPage.vue'),
        //   name:"staking",
        //   meta: {title: 'Staking'}
        // },
        // {
        //   path: "vault-app",
        //   component: () => import('@/pages/product/VaultPage.vue'),
        //   name:"vault",
        //   meta: {title: 'Vault App'}
        // },
      ]
    },
    {
      path: "/company",
      component: () => import('@/pages/company/CompanyPage.vue'),
      name:"company",
      children:[
        {
          path: "about-us",
          component: () => import('@/pages/company/AboutusPage.vue'),
          name:"about-us",
          meta: {title: 'About Us'}
        },
        {
          path: "contact-us",
          component: () => import('@/pages/company/ContactusPage.vue'),
          name:"contact-us",
          meta: {title: 'Contact Us'}
        },
        {
          path: "career",
          component: () => import('@/pages/company/CareerPage.vue'),
          name:"career",
          meta: {title: 'Career'}
        },
        // {
        //   path: "team",
        //   component: () => import('@/pages/company/TeamPage.vue'),
        //   name:"team",
        //   meta: {title: 'Our Team'}
        // },
      ]
    },
    {
      path: "/resource",
      component: () => import('@/pages/reosurce/ResourcePage.vue'),
      name:"resource",
      children:[
        {
          path: "academy",
          component: () => import('@/pages/reosurce/AcademyPage.vue'),
          name: "academy",
          meta: {title: 'Academy'}
        },
        {
          path: "education",
          component: () => import('@/pages/reosurce/EducationPage.vue'),
          name:"education",
          meta: {title: 'Education'}
        },
        {
          path: "faq",
          component: () => import('@/pages/reosurce/FaqPage.vue'),
          name:"faq",
          meta: {title: 'FAQ'}
        },
      ]
    },
    {
      path: "/developer",
      component: () => import('@/pages/developer/DeveloperPage.vue'),
      name:"developer",
      children:[
        {
          path: "bug-bounty",
          component: () => import('@/pages/developer/BountyPage.vue'),
          name:"bug-bounty",
        },
        {
          path: "bounty-form",
          component: () => import('@/pages/developer/FormPage.vue'),
          name:"bounty-form",
        },
        {
          path: "status",
          component: () => import('@/pages/developer/StatusPage.vue'),
          name:"status",
        },
        {
          path: "supported-protocol",
          component: () => import('@/pages/developer/SupportedPage.vue'),
          name:"supported-protocol",
          meta: {title: 'Supported Protocol'}
        },
      ]
    },
    {
      path: "/legal",
      component: () => import('@/pages/legal/LegalPage.vue'),
      children: [
        {
          path: "term-condition",
          component: () => import('@/pages/legal/TermPage.vue'),
          name: "term",
          meta: {title: 'Term & Conditions'}
        },
        {
          path: "privacy-policy",
          component: () => import('@/pages/legal/PrivacyPage.vue'),
          name: "privacy",
          meta: {title: 'Privacy & Policy'}
        },
      ],
    },
    {
      path:'/read/:title',
      component: () => import('@/pages/static-post/StaticPost.vue'),
      name:'read',
    },
    {
      path:'/:pathMatch(.*)*',
      component: () => import('@/pages/NotFound.vue'),
      name:'not-found',
    },
  ],
});

router.beforeEach((to) => {
  document.title = to.meta.title ?? 'Tennet | #1 Instutional Digital Asset Custody, Settlement & Insurance in Indonesia'
})

export default router;