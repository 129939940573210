const footer = {
  state() {
    return {
      logo: `<svg class="w-32 h-fit" width="1954" height="408" viewBox="0 0 1954 408" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_715_1479" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1954" height="408">
      <rect width="1954" height="408" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_715_1479)">
      <ellipse cx="203.682" cy="204.103" rx="198.682" ry="198.682" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M122.097 318.052C121.644 318.052 121.191 318.05 120.739 318.044H126.109C120.745 317.838 116.461 313.425 116.461 308.012V196.948L173.493 253.98C173.8 258.804 177.81 262.622 182.712 262.622H207.627V262.629H227.719C258.993 262.629 284.346 237.276 284.346 206.001C284.346 174.727 258.993 149.374 227.718 149.374H217.596V93.9521H229.328C291.212 93.9521 341.378 144.119 341.378 206.002C341.378 267.886 291.212 318.052 229.328 318.052H122.097Z" fill="#3A3A3C"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M200.397 93.5283H97.1676C81.6413 93.5283 69.0547 106.115 69.0547 121.641C69.0547 137.168 81.6413 149.754 97.1676 149.754H116.437V171.772L173.868 229.203V149.754H200.397V93.5283Z" fill="#4993CD"/>
      <path d="M467.146 88.5686V48.1709H722.998V88.5686H617.795V356.203H572.348V88.5686H467.146ZM940.53 261.521H755.374C762.107 300.236 792.405 323.801 832.803 323.801C860.997 323.801 886.246 311.598 899.711 291.82L933.797 314.543C914.861 342.738 876.146 362.095 832.803 362.095C762.528 362.095 712.451 311.598 712.451 244.689C712.451 177.78 760.844 127.283 826.911 127.283C892.979 127.283 941.372 173.151 941.372 238.377C941.372 245.531 940.951 253.105 940.53 261.521ZM755.795 227.015H898.87C896.766 189.984 868.151 165.156 827.332 165.156C790.722 165.156 762.107 189.563 755.795 227.015ZM1201.36 231.644V356.203H1157.18V234.59C1157.18 188.721 1136.98 165.998 1097 165.998C1058.71 165.998 1030.09 197.558 1030.09 237.956V356.203H985.908V133.174H1030.09V169.364C1042.3 144.536 1070.49 127.283 1105.84 127.283C1165.17 127.283 1201.36 164.314 1201.36 231.644ZM1473.67 231.644V356.203H1429.49V234.59C1429.49 188.721 1409.29 165.998 1369.31 165.998C1331.02 165.998 1302.4 197.558 1302.4 237.956V356.203H1258.22V133.174H1302.4V169.364C1314.61 144.536 1342.8 127.283 1378.15 127.283C1437.48 127.283 1473.67 164.314 1473.67 231.644ZM1744.72 261.521H1559.56C1566.3 300.236 1596.6 323.801 1636.99 323.801C1665.19 323.801 1690.44 311.598 1703.9 291.82L1737.99 314.543C1719.05 342.738 1680.34 362.095 1636.99 362.095C1566.72 362.095 1516.64 311.598 1516.64 244.689C1516.64 177.78 1565.03 127.283 1631.1 127.283C1697.17 127.283 1745.56 173.151 1745.56 238.377C1745.56 245.531 1745.14 253.105 1744.72 261.521ZM1559.98 227.015H1703.06C1700.96 189.984 1672.34 165.156 1631.52 165.156C1594.91 165.156 1566.3 189.563 1559.98 227.015ZM1938.26 312.86L1949.2 347.787C1937 356.203 1916.38 362.095 1896.18 362.095C1842.74 362.095 1815.81 331.376 1815.81 269.517V171.889H1765.31V133.174H1815.81V72.157H1859.99V133.174H1942.05V171.889H1859.99V268.675C1859.99 304.865 1873.46 322.96 1899.97 322.96C1914.28 322.96 1926.9 319.593 1938.26 312.86Z" fill="white"/>
      </g>
      </svg>
      `,
      companyName: "Tennet Depsitory Indonesia",
      email: "info@tennet.id",
      address: {
        address1: "Millenium Centennial Center Lantai 2",
        address2: "Jl. Jend. Sudirman Kav.25. DKI Jakarta",
      },
      supported: {
        title: "Licensed & Supported",
        logos: ["supported_1x.webp"],
      },
      blocks: [
        {
          multi: 1,
          childs: [
            {
              title: "Products",
              url: "#",

              childs: [
                {
                  title: "Business Solution",
                  url: "solution",
                },
                {
                  title: "Supported Protocol",
                  url: "supported-protocol",
                },
                // {
                //   title: "Wallet API & SDK",
                //   url: "api",
                // },
                // {
                //   title: "Self Custody",
                //   url: "self",
                // },
                // {
                //   title: "Smart Refill Wallet",
                //   url: "refill",
                // },
                // {
                //   title: "Staking",
                //   url: "staking",
                // },
                // {
                //   title: "Vault App",
                //   url: "vault",
                // },
              ],
            },
          ],
        },

        {
          multi: 1,
          childs: [
            {
              title: "Company",
              url: "#",
              childs: [
                {
                  title: "About Us",
                  url: "about-us",
                },
                {
                  title: "Career",
                  url: "career",
                },
                // {
                //   title: "Team",
                //   url: "team",
                // },
              ],
            },          
          ],
        },

        {
          multi: 1,
          childs: [
            // {
            //   title: "Developer",
            //   url: "#",
            //   childs: [
            //     {
            //       title: "Supported Protocol",
            //       url: "supported-protocol",
            //     },
            //     // {
            //     //   title: "Developer / API",
            //     //   url: "#",
            //     // },
            //     // {
            //     //   title: "Status",
            //     //   external: 1,
            //     //   url: "https://stats.uptimerobot.com/WE2ROuzyAl",
            //     // },
            //   ],
            // },
            {
              title: "Resource",
              url: "#",
              childs: [
                {
                  title: "Education",
                  url: "education",
                },
                {
                  title: "FAQ",
                  url: "faq",
                },
              ],
            },
            
          ],
        },

        {
          multi: 1, 
          childs: [
            {
              title: "Legal",
              url: "#",
              childs: [
                {
                  title: "Term and Condition",
                  url: "term",
                },
                {
                  title: "Privacy Policy",
                  url: "privacy",
                },
              ],
            }, 
          ]
        },

      ],
      copyright:
        "Copyright © 2024 PT Tennet Depository Indonesia. All Right Reserved.",
    };
  },
};

export default footer;
