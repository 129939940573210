const header = {
  state(){
      return {
        h_navbar: [
              {
                title: "Home",
                url: "home",
              },
              {
                title: "Products",
                url: "#",
                childs:[
                  {
                    title: "Business Solution",
                    url: "solution",
                  },
                  // {
                  //   title: "Wallet API & SDK",
                  //   url: "api",
                  // },
                  // {
                  //   title: "Self Custody",
                  //   url: "self",
                  // },
                  // {
                  //   title: "Smart Refill Wallet",
                  //   url: "refill",
                  // },
                  // {
                  //   title: "Staking",
                  //   url: "staking",
                  // },
                  // {
                  //   title: "Vault App",
                  //   url: "vault",
                  // },
                ]
              },
              
              {
                title: "Company",
                url: "#",
                childs:[
                  {
                    title: "About Us",
                    url: "about-us",
                  },
                  // {
                  //   title: "Team",
                  //   url: "team",
                  // },
                  {
                    title: "Career",
                    url: "career",
                  },
                ]
              },
              {
                title: "Resource",
                url: "#",
                childs:[
                  // {
                  //   title: "Academy",
                  //   url: "academy"
                  // },
                  {
                    title: "Education",
                    url: "education",
                  },
                  {
                    title: "Blog",
                    external:'https://tennet.id/blog'
                  },
                  {
                    title: "FAQ",
                    url: "faq",
                  },
                ]
              },
            ],
            information1: "#1 DIGITAL ASSET CUSTODY IN INDONESIA",
            information2: "Build on all major protocols, such as Bitcoin, Ethereum, Solana, Cardano, Tron, and many others.",
            information3: "Unlock A Practical Feature Stack and Scale Without Fear"
      }
  }
}

export default header